import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/generalJurnal'

export default {
  get (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  show (idJurnal) {
    return httpClient.get(`${endpoint}/${idJurnal}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.post(`${endpoint}/update`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  }
}
