<template>
  <div class="flex flex-col no-scroll-content">
    <!------------ header ------------->
    <div class="flex-none">
      <div class="p-2 vx-card shadow-none rounded-none border-solid d-theme-border-grey-light border border-b-0">
        <div class="flex flex-wrap space-x-3">
          <div class="flex items-center text-xs">
            <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
              <vs-select class="w-52 vs-select-small" :value="null" v-model="filter.id_proyek">
                <vs-select-item class="vs-select-small" text="Semua"/>
              <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
            </vs-select>
          </div>
          <div class="flex items-center">
            <label class="mr-1 text-xs whitespace-no-wrap">Periode</label>
            <vx-input-group>
              <template slot="prepend">
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_awal"></flat-pickr>
                </vx-input-group>
                <div class="text-xs px-2 flex items-center border border-r-0 border-l-0 border-solid rounded-none d-theme-border-grey-light" style="background-color: rgba(121,121,121,0.1); border-color: rgba(71,71,71,0.3)">
                  <span class="p-0">s/d</span>
                </div>
                <vx-input-group>
                  <flat-pickr class="w-full flatpickr-input rounded-none text-xs" v-model="filter.periode_akhir"></flat-pickr>
                </vx-input-group>
              </template>
            </vx-input-group>
          </div>
          <vs-input type="text" placeholder="Search" v-model="filter.search" @keyup.enter="getData"/>
          <vs-button class="px-3 py-2 text-xs text-center" color="primary" @click="refresh" :disabled="loading">
            {{ loading ? 'Loading,,,' : 'Filter' }}
          </vs-button>
        </div>
      </div>
      <div class="p-0 vx-card shadow-none rounded-none border-solid d-theme-border-grey-light border border-b-0">
        <div class="flex h-auto" :class="{'pointer-events-none': loading}">
          <ul class="flex items-center text-xs overflow-x-auto">
            <li v-for="(tab, index) in tabs.items"
                :key="index"
                :class="{'text-primary font-bold': isTabActive(tab.code), 'hover-bg-primary-transparent-25': !isTabActive(tab.code)}"
                @click="tabs.active = tab.code"
                class="cursor-pointer px-2 py-1 select-none whitespace-no-wrap border-solid d-theme-border-grey-light border-b-0 border-t-0 border-l-0 border-r">
              {{ tab.label }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!----------- content ------------->
    <div class="flex-grow">
      <div class="flex h-full">
        <div class="w-full d-theme-dark-bg">
          <div class="border border-solid d-theme-border-grey-light h-full">
            <div class="w-full flex justify-between" style="background-color: rgba(121,121,121,0.1);">
              <div class="flex items-center justify-start">
                <p class="text-xs font-medium p-2 text-dark text-bold">{{ data.loading ? 'Loading...' : 'Jurnal List' }}</p>
                <QueryEditor v-if="$can('view_query')" :code="currentQueryCode"/>
              </div>
            </div>
            <div class="h-full" :class="{'pointer-events-none opacity-50': loading}">
              <div class="tableSticky">
                <table class="table-fixed w-full h-full border-collapse">
                  <thead class="font-bold">
                    <tr class="d-theme-dark-bg">
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-16">Action</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left w-20">
                        <vs-checkbox size="small" class="text-xs" v-model="data.printAll" @change="togglePrintAll($event.target.checked)">Print</vs-checkbox>
                      </th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left w-32">No. Jurnal</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Tgl. Jurnal</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light left w-32">Tipe</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left w-24">User</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left w-20">Proyek</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left w-72">Keterangan</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Total</th>
                    </tr>
                  </thead>
                  <tbody class="font-medium">
                    <tr v-for="(item, index) in data.items" :key="index" class="hover:font-medium cursor-pointer hover-bg-primary-transparent-10" @dblclick="showModalView(item)">
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light break-all text-center">
                        <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer xs">
                          <vs-button class="py-2 px-3 small" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                          <vs-dropdown-menu>
                            <vs-dropdown-item class="p-1" @click="showModalView(item)"><span class="whitespace-no-wrap">View Detail</span></vs-dropdown-item>
                            <vs-dropdown-item class="p-1" @click="showModalEdit(item)" v-if="$can('update_general_jurnal')&& activeTab === 'GENERAL JURNAL'">Edit</vs-dropdown-item>
                            <vs-dropdown-item class="p-1" @click="confirmDelete(item.id)" v-if="$can('undo_posting_general_jurnal')"><span class="whitespace-no-wrap text-danger">Undo Posting</span></vs-dropdown-item>
                          </vs-dropdown-menu>
                        </vs-dropdown>
                      </td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light break-all text-center" @click.stop>
                        <vs-checkbox  size="small" v-model="item.checkedPrint">Print</vs-checkbox>
                      </td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light break-all text-left">{{ item.no_jurnal }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light break-all text-center">{{ item.tgl_jurnal }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light break-all text-left">{{ item.tipe }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light break-all text-left">{{ item.username }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light break-all text-left">{{ item.kode_proyek }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.keterangan }}</td>
                      <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light break-all text-right">{{ item.total | idr }}</td>
                    </tr>
                    <!--empty state-->
                    <tr v-if="data.items.length < 1">
                      <td colspan="9" class="text-xs text-center p-2">Tidak ada data.</td>
                    </tr>
                    <!--spacer-->
                    <tr v-if="data.items.length > 0" class="h-full">
                      <td colspan="9"></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap">{{ footer.grandTotal | idr }}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!------------ footer ------------->
    <div class="flex-none vx-card p-2 rounded-none border border-solid d-theme-border-grey-light border-t-0">
      <div class="flex flex-wrap items-center justify-between">
        <div class="flex items-center space-x-2">
          <vs-button v-if="$can('create_general_jurnal') && activeTab === 'GENERAL JURNAL'" color="primary" size="small" class="px-3" icon-pack="feather" icon="icon-plus" @click="modalAdd.active = true">Tambah Jurnal</vs-button>
        </div>
        <div class="flex items-center space-x-2">
          <vs-button color="primary" size="small" class="px-3" icon-pack="feather" icon="icon-printer" :disabled="checkedPrintItems.length < 1" @click="print">Print</vs-button>
          <vs-button color="primary" size="small" class="px-3" icon-pack="feather" icon="icon-printer" :disabled="checkedPrintItems.length < 1" @click="printSummary">Print Summary</vs-button>
        </div>
      </div>
    </div>

    <!--modals-->
    <GeneralJurnalAdd :isActive.sync="modalAdd.active" @success="refresh" :id-proyek="filter.id_proyek"/>
    <GeneralJurnalEdit :isActive.sync="modalEdit.active" @success="refresh" :id-jurnal="modalEdit.item.id"/>
    <GeneralJurnalView :isActive.sync="modalView.active" :id-jurnal="modalView.item.id"/>
  </div>
</template>

<script>
import GeneralJurnalRepository from '@/repositories/accounting/general-jurnal-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import printoutMixin from '@/utilities/mixins/printout-mixin'
import moment from 'moment'

export default {
  name: 'GeneralJurnal',
  components: {
    GeneralJurnalAdd: () => import('@/views/pages/accounting/general-jurnal/GeneralJurnalAdd'),
    GeneralJurnalEdit: () => import('@/views/pages/accounting/general-jurnal/GeneralJurnalEdit'),
    GeneralJurnalView: () => import('@/views/pages/accounting/general-jurnal/GeneralJurnalView'),
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor'),
    flatPickr
  },
  mixins: [printoutMixin],
  mounted () {
    this.getProyek()
  },
  computed: {
    activeTab () {
      return this.tabs.active
    },
    checkedPrintItems () {
      return _.filter(this.data.items, item => item.checkedPrint)
    },
    footer () {
      return { grandTotal: _.sumBy(this.data.items, item => parseFloat(item.total)) }
    },
    currentQueryCode () {
      const currentActiveTab = this.activeTab
      if (currentActiveTab === 'GENERAL JURNAL') {
        return 'GENERAL_JURNAL_LIST'
      } else if (currentActiveTab === 'SPK') {
        return 'GENERAL_JURNAL_SPK_LIST'
      } else if (currentActiveTab === 'SPK ADJUST') {
        return 'GENERAL_JURNAL_SPK_ADJUST_LIST'
      } else if (currentActiveTab === 'PO') {
        return 'GENERAL_JURNAL_PO_LIST'
      } else if (currentActiveTab === 'KOMISI') {
        return 'GENERAL_JURNAL_KOMISI_LIST'
      } else if (currentActiveTab === 'REIMBURSE') {
        return 'GENERAL_JURNAL_REIMBURSE_LIST'
      } else if (currentActiveTab === 'AP OTHER') {
        return 'GENERAL_JURNAL_AP_OTHER_LIST'
      } else if (currentActiveTab === 'KONTRAK') {
        return 'GENERAL_JURNAL_KONTRAK_LIST'
      } else if (currentActiveTab === 'IPL') {
        return 'GENERAL_JURNAL_IPL_LIST'
      } else if (currentActiveTab === 'POS') {
        return 'GENERAL_JURNAL_POS_LIST'
      } else if (currentActiveTab === 'CASH ADVANCE') {
        return 'GENERAL_JURNAL_CASH_ADVANCE_LIST'
      } else if (currentActiveTab === 'AR OTHER') {
        return 'GENERAL_JURNAL_AR_OTHER_LIST'
      } else if (currentActiveTab === 'FIXED ASSET') {
        return 'GENERAL_JURNAL_FA_LIST'
      } else if (currentActiveTab === 'PREPAID') {
        return 'GENERAL_JURNAL_PREPAID_LIST'
      } else if (currentActiveTab === 'PENGAKUAN PENDAPATAN UNIT') {
        return 'GENERAL_JURNAL_PPU_LIST'
      } else if (currentActiveTab === 'PENGAKUAN PENDAPATAN IPL') {
        return 'GENERAL_JURNAL_PPI_LIST'
      } else if (currentActiveTab === 'PAYMENT') {
        return 'GENERAL_JURNAL_PAYMENT_LIST'
      } else if (currentActiveTab === 'ALL') {
        return 'ALL_JURNAL_LIST'
      } else {
        return null
      }
    }
  },
  watch: {
    activeTab (newVal, oldVal) {
      if (newVal && !_.isEqual(newVal, oldVal)) {
        this.refresh()
      }
    }
  },
  data () {
    return {
      proyeks: [],
      loading: false,
      modalAdd: {
        active: false
      },
      modalEdit: {
        active: false,
        item: {}
      },
      modalView: {
        active: false,
        item: {}
      },
      filter: {
        id_proyek: null,
        periode_awal: moment().startOf('month').format('YYYY-MM-DD'),
        periode_akhir: moment().endOf('month').format('YYYY-MM-DD'),
        search: ''
      },
      tabs: {
        items: [
          { code: 'GENERAL JURNAL', label: 'GENERAL JURNAL' },
          { code: 'SPK', label: 'SPP' },
          { code: 'SPK ADJUST', label: 'SPP ADJUST' },
          { code: 'PO', label: 'PO' },
          { code: 'KOMISI', label: 'KOMISI' },
          { code: 'REIMBURSE', label: 'REIMBURSE' },
          { code: 'AP OTHER', label: 'AP OTHER' },
          { code: 'KONTRAK', label: 'KONTRAK' },
          { code: 'IPL', label: 'IPL' },
          { code: 'POS', label: 'POS' },
          { code: 'CASH ADVANCE', label: 'CASH ADVANCE' },
          { code: 'AR OTHER', label: 'AR OTHER' },
          { code: 'FIXED ASSET', label: 'FIXED ASSET' },
          { code: 'PREPAID', label: 'PREPAID' },
          { code: 'PENGAKUAN PENDAPATAN UNIT', label: 'PENG. PENDAPATAN UNIT' },
          { code: 'PENGAKUAN PENDAPATAN IPL', label: 'PENG. PENDAPATAN IPL' },
          { code: 'PAYMENT', label: 'PAYMENT' },
          { code: 'ALL', label: 'ALL' }
        ],
        active: 'GENERAL JURNAL' // by code
      },
      data: {
        items: [],
        printAll: false
      }
    }
  },
  methods: {
    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getData () {
      this.loading = true

      const params = {
        type: this.activeTab,
        id_proyek: this.filter.id_proyek,
        periode_awal: this.filter.periode_awal,
        periode_akhir: this.filter.periode_akhir,
        search: this.filter.search
      }
      GeneralJurnalRepository.get(params)
        .then(response => {
          this.data.items = _.map(response.data.data, item => {
            item.checkedPrint = false
            return item
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    delete (id) {
      GeneralJurnalRepository.delete(id)
        .then(response => {
          this.refresh()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            this.notifyErrorUsingDialog(errors)
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
    },

    showModalView (item) {
      this.modalView.item = item
      this.modalView.active = true
    },

    showModalEdit (item) {
      this.modalEdit.item = item
      this.modalEdit.active = true
    },

    refresh () {
      this.getData()
    },

    print () {
      const ids = _.map(this.checkedPrintItems, item => item.id)
      if (ids.length > 50) {
        this.notifyWarning('Hanya dapat mencetak maksimal 50 items bersamaan.')
      } else {
        this.showPrintOutFile('JURNAL', { ids: ids })
      }
    },

    printSummary () {
      const ids = _.map(this.checkedPrintItems, item => item.id)
      if (ids.length > 50) {
        this.notifyWarning('Hanya dapat mencetak maksimal 50 items bersamaan.')
      } else {
        this.showPrintOutFile('JURNAL_SUMMARY', { ids: ids })
      }
    },

    isTabActive (tab) {
      return tab === this.activeTab
    },

    togglePrintAll (isPrinted) {
      this.data.items = _.map(this.data.items, item => {
        item.checkedPrint = isPrinted
        return item
      })
    },

    confirmDelete (id) {
      const item = _.find(this.data.items, item => item.id === id)
      let text
      if (this.activeTab === 'PREPAID') {
        const date = moment(item.tgl_jurnal)
        const monthName = date.format('MMMM')
        const yearName = date.format('YYYY')
        text = `Menghapus salah satu prepaid jurnal juga akan menghapus prepaid jurnal lainnya dengan proyek dan periode yang sama. Yakin ingin menghapus prepaid jurnal pada proyek ${item.kode_proyek} pada periode ${monthName} ${yearName}?`
      } else {
        text = 'Menghapus data jurnal sama dengan melakukan undo posting. Lanjutkan?'
      }
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: text,
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    }
  }
}
</script>

<style scoped>
.tableSticky {
  overflow: auto;
  height: calc(100vh - 305px);
}

.tableSticky thead tr {
  position: sticky;
  top: -1px;
  z-index: 101;
}

.tableSticky tfoot tr {
  position: sticky;
  bottom: -1px;
  z-index: 101;
}
</style>
